import React, { createContext, useEffect, useState } from "react";
import { STORAGE } from "../config/storage";
import { isEmpty } from "lodash";
import { meService } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { getMyUploadedDocuments } from "../services/client";
import { getToken } from "../utils/token";

export const AuthContext = createContext(null);

export const AuthContextConsumer = AuthContext.Consumer;

export const AuthContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    setIsLoading(true);

    const token = getToken();

    if (token) {
      try {
        if (token) {
          const response = await Promise.all([
            meService(),
            getMyUploadedDocuments()
          ]);

          const result = response?.length ? response[0] : null;
          const documentsResult = response?.length > 1 ? response[1] : null;

          const documents = documentsResult?.data?.data?.docs ?? [];

          if (
            !isEmpty(result?.data?.data?.user) &&
            result?.data?.data?.user?.type === "ADMIN"
          ) {
            const u = {
              ...result?.data?.data?.user,
              documents: documents,
              notificationsCount: result?.data?.data?.notifications?.length
                ? result?.data?.data?.notifications[0]
                : 0,
              notifications:
                result?.data?.data?.notifications?.length != 2
                  ? []
                  : result?.data?.data?.notifications[1]
            };

            //localStorage.setItem(STORAGE.USER, JSON.stringify(u));
            setUser(u);
          }
        }
      } catch (error) {
        //   console.log(error)
      }
    }

    setIsLoading(false);
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
