import { useEffect } from "react";
import { STORAGE } from "../../config/storage";
import { redirectToLogin } from "../../utils";
import { FullPageLoader } from "../../components/loader";
import { QueryClient } from "react-query";
import { queryClient } from "../../App";
import { removeAdminToken, removeUserToken } from "../../utils/token";

export const Logout = () => {
  useEffect(() => {
    queryClient.invalidateQueries();
    //  localStorage.removeItem(STORAGE.USER)
    // localStorage.removeItem(STORAGE.ADMIN)
    removeUserToken();
    removeAdminToken();
    localStorage.clear();
    redirectToLogin();
  }, []);

  return <FullPageLoader />;
};
