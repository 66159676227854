import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  approveRejectRequestService,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllUsersServices,
  getIBWithdrawalRequestsService,
  getRequestsByType,
  getSettingsValueService,
  makeUserIbService,
  updateIBWithdrawRequest,
  updateSettingValueService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import SearchUserField from "../../../components/admin/SearchUser";
import downloadFile from "../../../utils/downloadFile";
import convertJsonToCsv from "../../../utils/convertJsonToCsv";

export const SETTINGS_TABS = [
  {
    tab: "Deposit & Withdrawal",
    keys: ["MINIMUM_DEPOSIT_AMOUNT", "MINIMUM_WITHDRAWAL_AMOUNT", "INR_RATE"]
  },
  {
    tab: "Email Configuration",
    keys: ["MAIL_HOST", "MAIL_PORT", "MAIL_USERNAME", "MAIL_PASSWORD"]
  }
];

export default function AdminSettings() {
  return (
    <PageLayout
      title="Settings"
      breadcrumb={[
        {
          title: "Home",
          path: "/admin/dashboard"
        },
        {
          title: "Settings"
        }
      ]}
    >
      <Row>
        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = () => {
  const [type, setType] = useState("PENDING");
  const [currentPage, setCurrentPage] = useState(1);
  const [modifyRequest, setModifyRequest] = useState(null);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [forceSearch, setForceSearch] = useState(0.01);
  const [settings, setSettings] = useState([]);
  const [settingToUpdate, setSettingToUpdate] = useState(null);
  const [isUpdateSetting, setIsUpdatingSetting] = useState(false);

  const showToast = useShowToast();

  const { isLoading, error, data, refetch } = useQuery(
    ["admin-settings", currentPage, forceSearch],
    () => getSettingsValueService(),
    {
      //  refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setSettings(data?.data?.data?.setting?.data ?? []);
  }, [data, isLoading]);

  const onChangeSettingValue = (e) => {
    setSettings((_sts) => {
      let updatedSettings = _.map(_sts, (_s) => {
        return {
          ..._s,
          _value: _s?._tag === e?.target?.name ? e?.target?.value : _s._value
        };
      });

      return updatedSettings;
    });
  };

  const updateSettings = async (_settingsArray) => {
    setSettingToUpdate(_settingsArray);
    setIsUpdatingSetting(true);

    try {
      const result = await Promise.all(
        _.map(_settingsArray, (_s) =>
          updateSettingValueService({ id: _s?.id, _value: _s?._value })
        )
      );

      showToast("Success", "Setting updated successfully");
    } catch (error) {
      showToast(
        "Success",
        error.message ?? "Error in updating setting",
        "error"
      );
    }

    setSettingToUpdate(null);
    setIsUpdatingSetting(false);
  };

  return (
    <>
      <Col xl={12}>
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DepositTable
                requests={settings ?? []}
                onUpdateStatus={updateSettings}
                onChangeSettingValue={onChangeSettingValue}
                settingToUpdate={settingToUpdate}
                isUpdateSetting={isUpdateSetting}
              />
              <br />
              {/* <Pagination 
             
                lastPage={wrequests?.last_page}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              /> */}
            </>
          )}
        </>
      </Col>
    </>
  );
};

const ModifyRequestAlert = ({
  request,
  setRequest,
  isUpdatingStatus,
  onRequestChange,
  ibPlans
}) => {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const cancelRef = useRef();

  const disableSubmitButton = () => {
    //!_.isEmpty(request?.parent_user) &&
    //_.isEmpty(request?.parent_user?.parent_id)

    if (
      !_.isEmpty(request?.parent_user) &&
      _.isEmpty(request?.parent_user?.parent_id)
    ) {
      if (!selectedPlan) {
        return true;
      }
    }

    return false;
  };

  return (
    <AlertDialog
      isOpen={request ? true : false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRequest(null);
      }}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {request?.status == "ACCEPTED" ? "Accept" : "Reject"} IB Withdraw
            Request ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, You want to &nbsp;
            {request?.status == "ACCEPTED" ? "accept" : "reject"} withdraw
            commission request of amount {request?.amount} for {request?.name} ?
            <br />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                {!isUpdatingStatus ? (
                  <button
                    onClick={() => {
                      setRequest(null);
                    }}
                    className="mc-btn gray"
                    style={{ textTransform: "uppercase", minHeight: "32px" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  disabled={disableSubmitButton()}
                  onClick={() => {
                    if (disableSubmitButton()) return;

                    onRequestChange(selectedPlan ? parseInt(selectedPlan) : 0);
                  }}
                  className="mc-btn green"
                  style={{
                    textTransform: "uppercase",
                    minHeight: "32px",
                    opacity: disableSubmitButton() ? 0.4 : 1
                  }}
                >
                  {isUpdatingStatus ? <Spinner /> : "Confirm"}
                </button>
              </div>
            </Flex>

            {/* <Button
              ref={cancelRef}
              onClick={() => {
                setRequest(null)
              }}
            >
              Cancel
            </Button>
            <Button
              className="mc-bn red"
              onClick={() => {
                setRequest(null)
              }}
              ml={3}
            >
              Delete
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
