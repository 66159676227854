import React from "react";
import { Link } from "react-router-dom";
import { Text, Image } from "./elements";

export default function Logo({ src, alt, name, href, className }) {
  return (
    <>
      <Image
        src={src}
        alt={alt}
        style={{
          height: "75px !important",
          width: "150px !important",
          maxWidth: "150px"
        }}
      />
    </>
  );
}
