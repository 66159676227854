import Cookies from "js-cookie";
import { STORAGE } from "../config/storage";
import APP from "../config/app";

const isLocalDevelopment = () => {
  return window?.location?.href?.includes("localhost") ? true : false;
};

const localCookieOptions = { expires: 7, domain: "localhost", path: "/" };
const domainCookieOptions = {
  expires: 7,
  domain: ".strelasoft.com",
  path: "/"
};

export const setToken = (token) => {
  if (isLocalDevelopment()) {
    Cookies.set(STORAGE.USER, token, localCookieOptions);
    return;
  }

  Cookies.set(STORAGE.USER, token, domainCookieOptions);
};

export const getToken = () => {
  return Cookies.get(STORAGE.USER);
};

export const removeUserToken = () => {
  if (isLocalDevelopment()) {
    Cookies.remove(STORAGE.USER, localCookieOptions);
    return;
  }

  Cookies.remove(STORAGE.USER, domainCookieOptions);
};

export const setAdminToken = (token) => {
  if (isLocalDevelopment()) {
    Cookies.set(STORAGE.ADMIN, token, localCookieOptions);
    return;
  }

  Cookies.set(STORAGE.ADMIN, token, domainCookieOptions);
};

export const getAdminToken = (token) => {
  if (isLocalDevelopment()) {
    Cookies.set(STORAGE.ADMIN, token, localCookieOptions);
    return;
  }

  Cookies.set(STORAGE.ADMIN, token, domainCookieOptions);
};

export const removeAdminToken = () => {
  if (isLocalDevelopment()) {
    Cookies.remove(STORAGE.ADMIN, localCookieOptions);
    return;
  }

  Cookies.remove(STORAGE.ADMIN, domainCookieOptions);
};

export const setUserSpoofToken = (_token) => {
  if (isLocalDevelopment()) {
    Cookies.set(STORAGE.USER_WEBSITE, _token, localCookieOptions);
    return;
  }

  Cookies.set(STORAGE.USER_WEBSITE, _token, domainCookieOptions);
};
