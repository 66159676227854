import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "../../../components/elements/Table";
import {
  Anchor,
  Heading,
  Text,
  Input,
  Image,
  Icon,
  Button
} from "../../../components/elements";
import _ from "lodash";
import { Avatar, Flex, Grid, Box } from "@chakra-ui/react";
import { Badge } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { LabelField } from "../../../components/fields";

const thead = ["ID", "User", "Permissions", "", ""];
// {req: {}, status}

const DepositTable = ({
  requests,
  onChangeStatus,
  setRevokeUser,
  allPermissions,
  handleAddPermission,
  handleDeletePermission
}) => {
  const formatName = (_name) => {
    if (!_name) return "";

    return _name.split(".").join(" ").split("-").join(" ").split("_").join(" ");
  };

  const renderPermissions = (_permissions, user_id) => {
    if (!_permissions || !_permissions?.length)
      return <>No Permissions Added</>;

    return (
      <>
        <Flex display="flex" direction="row" wrap="wrap">
          {_.map(_permissions, (p) => {
            return (
              <>
                <Box m={1}>
                  <Badge
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      alignItems: "center",
                      display: "inline-flex"
                    }}

                    //  style={{  }}
                  >
                    {formatName(p?.name)}{" "}
                    <Box
                      ml={2}
                      m={1}
                      as={MdClose}
                      cursor="pointer"
                      onClick={() => {
                        handleDeletePermission(p?.id, user_id);
                      }}
                    />
                  </Badge>
                </Box>
              </>
            );
          })}
        </Flex>
      </>
    );
  };

  const permissionOptions = (permissions) => {
    const permissionNames = _.map(permissions, (p) => p.name);

    const permissionNotPresent = _.filter(allPermissions, (p) =>
      permissionNames?.includes(p?.name) ? false : true
    );

    return [
      {
        title: "Select Permission",
        value: undefined
      },
      ..._.map(permissionNotPresent, (p) => {
        return {
          title: formatName(p?.name),
          value: p?.id
        };
      })
    ];
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {requests?.length ? (
            <>
              {_.map(requests, (_r) => {
                if (_r?.email === "support@support@Aritrage Primeltd.com")
                  return null;

                return (
                  <Tr key={_r?.id}>
                    <Td> # {_r?.id} </Td>
                    <Td>
                      {_r?.name} <br /> {_r?.email}{" "}
                    </Td>

                    <Td>
                      {renderPermissions(_r?.permissions, _r?.id)}

                      <br />
                      <LabelField
                        option={permissionOptions(_r?.permissions)}
                        onChange={(e) => {
                          if (!e?.target?.value) return;

                          handleAddPermission(
                            _r?.id,
                            parseInt(e?.target?.value)
                          );
                        }}
                      />
                    </Td>

                    <Td>
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <button
                          onClick={() => {
                            setRevokeUser({
                              ..._r
                            });
                          }}
                          className="mc-btn primary"
                        >
                          Revoke
                        </button>
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan={thead?.length ?? 1}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  No Data found
                </div>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DepositTable;
