import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Grid,
  Flex,
  Input,
  Text,
  Select,
  useDisclosure,
  Collapse,
  Link,
  Checkbox
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, FieldArray, useFormik } from "formik";
import _ from "lodash";
import { registerForAITradingSystemService } from "../../../services/client";
import { useShowToast } from "../../../hooks";
import { CONFIG_KEYS, setConfig } from "../../../services/config.service";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FormWrapper } from "../../../components/elements/Input";
import { LabelField } from "../../../components/fields";

const aiFormSchema = Yup.object().shape({
  investment: Yup.number()
    .min(0.01)
    .default(1000)
    .required("Minimum investment amount is required"),
  account_id: Yup.string().trim().required("Account is required"),
  password: Yup.string().trim().required("Trading password is required"),
  symbol: Yup.string().trim().required("Symbol (Stratergy) is required"),
  myshare: Yup.number()
    .default(70)
    .min(0)
    .max(100)
    .required("My Share is required"),
  aishare: Yup.number().default(30).min(0).required("AI Share is required"),
  consent: Yup.boolean()
    .required("Please accept the terms and conditions to proceed")
    .oneOf([true], "The terms and conditions must be accepted.")
});

const AIFormModal = ({ isOpen, onClose, name, clientId, accounts }) => {
  return <></>;

  const toast = useShowToast();

  const formik = useFormik({
    initialValues: {
      investment: 5000,
      account_id: undefined,
      password: "",
      symbol: "",
      myshare: 70,
      aishare: 30,
      consent: false
    },
    enableReinitialize: true,
    validationSchema: aiFormSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      if (!values?.consent) {
        actions.setSubmitting(false);
        actions.setFieldTouched("consent");
        actions.setFieldError(
          "consent",
          "Please accept the terms and conditions to proceed"
        );
        return;
      }

      try {
        const result = await registerForAITradingSystemService(values);

        if (result?.data?.success) {
          toast("Success", "Registration request sent successfully");

          setConfig(CONFIG_KEYS.NEVER_SHOW_AI_FORM_MODAL_SHOWN, true);
          onClose();
        }
      } catch (error) {}

      actions.setSubmitting(false);
    }
  });

  const neverShowAgain = () => {
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Expression of Interest in AI Trading System</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="75vh" overflowY="auto">
            <form onSubmit={formik.handleSubmit}>
              <Grid gap={6}>
                <Box>
                  <b>
                    Subject:&nbsp; Expression of Interest in AI Trading System
                    Dear
                  </b>
                </Box>
                <Box>
                  Dear Sir/Madam, <br />
                  <Box>
                    I am writing to express my keen interest in participating in
                    your AI (Robot) trading system with an investment of ${" "}
                    <UnderLineInput
                      type="number"
                      name={"investment"}
                      value={formik?.values.investment}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.investment &&
                        formik?.touched?.investment
                          ? formik?.errors?.investment
                          : false
                      }
                    />
                    . With a comprehensive understanding of the associated risks
                    involved in AI trading and thorough examination of the track
                    record of your AI system, I am inclined to proceed with this
                    investment opportunity.
                  </Box>
                </Box>
                <Box>
                  Kindly find below my detailed information for the purpose of
                  the investment:
                </Box>

                <Grid gap={4} width="100%" maxWidth="320px">
                  <Box>
                    <LabelField
                      type="text"
                      label="name"
                      value={name}
                      isReadOnly={true}
                    />
                  </Box>

                  <Box>
                    <LabelField
                      type="text"
                      label=" Client ID"
                      value={clientId}
                      isReadOnly={true}
                    />
                  </Box>

                  <Box>
                    <LabelField
                      option={_.map(accounts, (_acc) => {
                        return { title: _acc?.login, value: _acc?.login };
                      })}
                      label=" MT5 Account ID"
                      name={"account_id"}
                      value={formik?.values.account_id}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.account_id &&
                        formik?.touched?.account_id
                          ? formik?.errors?.account_id
                          : false
                      }
                    />
                  </Box>

                  <Box>
                    <LabelField
                      label=" Trading Password"
                      type="text"
                      name={"password"}
                      value={formik?.values.password}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.password && formik?.touched?.password
                          ? formik?.errors?.password
                          : false
                      }
                    />
                  </Box>
                  <Box>
                    <LabelField
                      label=" Symbol (Strategy)"
                      type="text"
                      name={"symbol"}
                      value={formik?.values.symbol}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.symbol && formik?.touched?.symbol
                          ? formik?.errors?.symbol
                          : false
                      }
                    />
                  </Box>
                </Grid>
                <Box>
                  According to the proposed profit sharing arrangement, the
                  distribution will be allocated based on the following ratio:
                </Box>
                <Grid gap={2}>
                  {/* <Box>
                    My Share:
                    <UnderLineInput
                      type="number"
                      name={"myshare"}
                      value={formik?.values.myshare}
                      onBlur={formik?.handleBlur}
                      onChange={(e) => {
                        formik?.handleChange(e)
                        let val = e?.target?.value

                        if (val != undefined || val != "") {
                          let n = parseFloat(val)
                          formik.setFieldValue(
                            "aishare",
                            100 - n < 0 ? 0 : 100 - n > 100 ? 100 : 100 - n
                          )
                        }
                      }}
                      errorMessage={
                        formik?.errors?.myshare && formik?.touched?.myshare
                          ? formik?.errors?.myshare
                          : false
                      }
                    />
                    % <br />
                  </Box>
                  AI System's Share: {formik?.values.aishare}%<Box></Box> */}
                  <Box>
                    <TermsAndComments
                      errorMessage={
                        formik?.errors?.consent && formik?.touched?.consent
                          ? formik?.errors?.consent
                          : false
                      }
                      consent={formik?.values?.consent}
                      onChangeConsent={(val) => {
                        formik.setFieldValue("consent", val);
                      }}
                    />
                  </Box>
                </Grid>

                <br />
                <Flex align="center" justify="flex-end">
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    type="submit"
                    isLoading={formik?.isSubmitting}
                    colorScheme="blue"
                  >
                    Send Request
                  </Button>
                </Flex>
                <Box></Box>
              </Grid>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AIFormModal;

function UnderLineInput(props) {
  const { errorMessage } = props;

  return (
    <Box display="inline-flex" flexDirection="column">
      <Input
        _focus={{
          outline: 0,
          border: "0",
          borderBottom: "1px solid "
        }}
        border="0"
        borderBottom="1px solid "
        borderRadius="0"
        display="inline-block"
        width="100%"
        maxWidth="120px"
        height="24px"
        {...props}
      />
      {errorMessage ? (
        <Text color="red.500" fontSize="xs">
          {errorMessage}
        </Text>
      ) : (
        ""
      )}
    </Box>
  );
}

function UnderSelectInput(props) {
  const { errorMessage, options } = props;

  return (
    <Box display="inline-flex" flexDirection="column">
      <Select
        _focus={{
          outline: 0,
          border: "0",
          borderBottom: "1px solid "
        }}
        border="0"
        borderBottom="1px solid "
        borderRadius="0"
        display="inline-block"
        width="100%"
        maxWidth="200px"
        height="24px"
        {...props}
      >
        <option value={undefined}>Select Account</option>
        {_.map(options, (_opt) => (
          <option value={_opt}>{_opt}</option>
        ))}
      </Select>

      {errorMessage ? (
        <Text color="red.500" fontSize="xs">
          {errorMessage}
        </Text>
      ) : (
        ""
      )}
    </Box>
  );
}

const TermsAndComments = ({ errorMessage, consent, onChangeConsent }) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex direction="column">
        <FormWrapper errorMessage={errorMessage}>
          <Box as="span" flex="1" textAlign="left">
            <Checkbox
              isChecked={consent}
              onChange={(e) => {
                onChangeConsent(e?.target?.checked);
              }}
              borderColor="var(--chakra-colors-blue-500)"
              borderWidth="2px"
            ></Checkbox>
            &nbsp; By expressing your interest in AI Trading System you accept
            the &nbsp;
            <Link
              textDecoration="underline"
              onClick={() => {
                if (isOpen) {
                  onClose();
                } else {
                  onOpen();
                }
              }}
            >
              terms and conditions.
            </Link>
          </Box>
        </FormWrapper>

        <Box mt={2}>
          <Collapse
            in={isOpen}
            transition={{ exit: { delay: 0 }, enter: { duration: 0.5 } }}
          >
            <Flex align="center" justify="space-between">
              <Box>AI Terms And Conditions</Box>

              <Box>
                <Box
                  cursor="pointer"
                  as={isOpen ? FaChevronUp : FaChevronDown}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Box>
            </Flex>

            <Grid gap={1} fontSize="xs">
              <Box>
                <b>Reliability Disclaimer:</b>
                The AI trading system operates solely on algorithms without
                human intervention, making it susceptible to potential
                fluctuations in generating profits on individual trades. Hence,
                its reliability cannot be fully guaranteed.
              </Box>
              <Box>
                <b> Acknowledgment of Risks:</b> Users must acknowledge the
                inherent risks associated with AI trading and accept the
                outcomes, regardless of the results obtained.
              </Box>
              <Box>
                <b>Manual Trade Responsibility:</b> Should a client choose to
                engage in manual trading, the AI trading system will not be held
                accountable for any resulting profits or losses. The client will
                bear full responsibility in such instances.
              </Box>
              <Box>
                <b>Minimum Deposit Requirement:</b> A minimum deposit of $5000
                is mandatory to access the AI trading system. Deposit above
                $5000 must be in multiple of 5000 only
              </Box>
              <Box>
                <b>Withdrawal Protocol:</b> During the initial 3-month period,
                no withdrawals are permitted. Subsequently, clients must request
                withdrawals through the CRM. Open trades will be closed at the
                time of withdrawal, regardless of their profit or loss status.
              </Box>
              <Box>
                <b>Profit Withdrawal Process:</b>
                Profit withdrawals are feasible only at the end of each month
                and can be requested between the 1st and 5th of the month
                through the CRM. All withdrawals will be processed exclusively
                in USDT.
              </Box>
              <Box>
                <b>Unauthorized Activity:</b>
                Any unauthorized activities, including copy trading, discovered
                on a client's account, will result in immediate termination of
                the account. All ongoing trades, irrespective of their financial
                status, will be closed. The client will be held solely
                accountable for any such activities.
              </Box>
              <Box>
                <b>Liability Limitation:</b> Strela Soft shall not be held
                responsible for any profits or losses incurred through the AI
                trading system. Strela Soft solely functions as the platform
                service provider. By proceeding with the use of the AI trading
                system, I acknowledge and agree to all the terms and conditions
                specified above and by providing the aforementioned details in
                this email, I confirm my full understanding and acceptance of
                all the terms and conditions associated with the AI trading
                system.
              </Box>

              <Box>
                In the event that an account enters a margin call status, it is
                imperative that the account holder promptly prepares an
                additional amount equivalent to the initial capital to meet the
                requirements.
              </Box>

              <Box>
                <b>Account Deactivation:</b> <br />
                <b>Client Request:</b> You can deactivate the AI trading system
                on your account by sending a confirmation email requesting its
                termination. <br />
                <b>Deactivation Processing:</b> We will deactivate the AI system
                within 24 business hours of receiving your confirmation email.
                <br />
                <b>Open Trade Closure (Default)</b> By default, all open trades
                will be closed upon deactivation, regardless of their profit or
                loss status. <br />
                <b>Keeping Trades Open:</b> If you wish to keep open trades
                active during deactivation, you must explicitly state this in
                your confirmation email. <br />
                <b>Default Deactivation Process:</b> In the absence of any
                instructions regarding open trades, the AI system will be
                deactivated by closing all trades.
                <br />
              </Box>
            </Grid>
          </Collapse>
        </Box>
      </Flex>
    </>
  );
};
