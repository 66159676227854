import React from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor } from "../elements";
import { Avatar } from "@chakra-ui/react";
import { useShowAIForm } from "../../hooks/useShowAIForm";
import { useNavigate } from "react-router-dom";
import AIFormModal from "../../pages/master/AIForm";

export default function ProfileDropdown({
  name,
  username,
  image,
  dropdown,
  accounts,
  clientId
}) {
  const aiHook = useShowAIForm();
  const navigate = useNavigate();

  return (
    <>
      <Dropdown className="mc-header-user">
        <Dropdown.Toggle className="mc-dropdown-toggle">
          <Avatar name={name} />

          <DuelText title={name} descrip={username} size="xs" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end" className="mc-dropdown-paper">
          {/* {window.location?.href?.includes("/admin") ||
          window.location?.href?.includes("/ib") ? null : (
            <Anchor
              key="12wqw"
              icon="lock"
              text="AI FORM"
              onClick={() => {
                aiHook.onOpen()
                // navigate("/")
              }}
              className="mc-dropdown-menu"
            />
          )} */}

          {dropdown.map((item, index) => (
            <Anchor
              key={index}
              href={item.path}
              icon={item.icon}
              text={item.text}
              onClick={item?.onClick ?? undefined}
              className="mc-dropdown-menu"
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {accounts?.length && !window?.location?.href?.includes("/dashboard") ? (
        <AIFormModal
          isOpen={aiHook?.isOpen}
          onClose={aiHook?.onClose}
          name={name ?? ""}
          clientId={clientId ?? ""}
          accounts={accounts ?? []}
        />
      ) : null}
    </>
  );
}
