import axios from "axios";
import axiosRetry from "axios-retry";
import APP from "../config/app";
import { STORAGE } from "../config/storage";
import { isEmpty } from "lodash";
import { redirectToLogin } from "../utils";
import { getToken, removeAdminToken, removeUserToken } from "../utils/token";

const api = axios.create({ baseURL: APP.BASE_API_URL });

axiosRetry(api, { retries: 3 });

api.interceptors.request.use(function (config) {
  try {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {}

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      localStorage.removeItem(STORAGE.USER);
      removeUserToken();
      removeAdminToken();
      redirectToLogin();
      return Promise.reject(error);
    }
  }
);

export default api;
